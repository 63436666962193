<template>
  <modal
    :isModalStatic="true"
    :isHideHeaderCloseButton="true"
    :isHideFooter="true"
    :modalSizeCssClass="isMobile ? 'plump' : 'sm'"
    componentName="ZingalaModal">
    <template v-slot:body>
      <template v-if="isMobile">
        <h3 class="fn-lg blue text-center spacing top md spacing bottom md">感謝您選擇使用無卡分期</h3>
        <h5 class="text-center">填寫申請資料需於訂單成立20分鐘內完成</h5>
        <h5 class="text-center">
          資料審核期限至
          <b class="value spacing fn-md">{{ showPaymentReviewDate }} 止</b>
        </h5>
        <h5 class="text-center">逾期系統將自動取消訂單</h5>
        <p class="text-center">
          <time class="countdown">{{ countDown }}</time>
          秒後跳轉
        </p>
      </template>
      <template v-else>
        <h3 class="align center">感謝您選擇使用無卡分期！</h3>
        <p class="align center">
          填寫申請資料需於訂單成立 20 分鐘內完成，資料
          <span class="notice font-bold">審核期限至 {{ showPaymentReviewDate }} 止</span>
          ，逾期系統將自動取消訂單。
        </p>
        <p class="align center">
          <time class="countdown">{{ countDown }}</time>
          秒後跳轉
        </p>
      </template>
    </template>
  </modal>
</template>

<script>
import { getState } from '@/helpers/vuex';
import { invokeRedirectFinishPage } from '@/helpers/order-process';
import Modal from '@/components/common/common-modal.vue';
import { isMobile } from '@/helpers/is-from-app';

const COUNT_DOWN_SECOND = 5;

export default {
  name: 'ZingalaModal',
  data() {
    return {
      countDown: COUNT_DOWN_SECOND,
    };
  },
  components: {
    Modal,
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
        return;
      }
      invokeRedirectFinishPage();
    },
  },
  computed: {
    showPaymentReviewDate: getState('OrderFinishInfo', 'paymentReviewDate'),
    isMobile,
  },
};
</script>
