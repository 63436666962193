const regexes = [
  /^[0-9]{15,16}$/, // 15-16 字的數字 (美國運通)
];

const getMessage = '卡號格式不正確';
const validate = (value) => regexes.every((x) => x.test(value));

/** 自訂信用卡卡號格式 */
export default {
  getMessage,
  validate,
};
