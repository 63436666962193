import { isMobile } from '@/helpers/is-from-app';
import { commitMutation, commitMutations, renderState } from '@/helpers/vuex';
import { navigateTo } from '@/helpers/router';
import { generateExecScript } from '@/helpers/execScript';

const finishedPath = isMobile() ? '/m/cart/finished' : '/cart/finished';

export const invokeRedirectFinishPage = () => {
  const execScript = renderState('OrderFinishInfo', 'execScript');
  if (execScript && typeof execScript === 'string') {
    generateExecScript(execScript);
    return;
  }
  // 返回結帳頁
  commitMutation('Step1Component', 'dynamicComponent', 'Checkout');
  window.location.replace(finishedPath);
};

export const invokePaymentTypeRedirect = ({ polling, qrCodePayment }) => {
  if (!isMobile() && polling && qrCodePayment) {
    // 用來產 全盈+PAY 的 QrCode 網址 & 組出輪詢網址
    commitMutations('OrderFinishInfo', {
      plusPayQrCode: qrCodePayment,
      plusPayPollingUrl: `${process.env.VUE_APP_ONLINE}${polling}`,
    });
    navigateTo('/cart/pluspay');
    return;
  }
  invokeRedirectFinishPage();
};
