export const deliveryEnum = {
  /** 免配送 */
  noDelivery: '0',
  /** 宅配到府 */
  deliveryToHome: '1',
  /** 門市取貨 */
  deliveryToStore: '2',
};

export const componentLut = {
  100: 'creditcard-payer',
  99: 'creditcard-payer',
  199: 'creditcard-payer',
  200: 'store-payer',
  300: 'store-payer',
  400: 'store-payer',
  401: 'store-payer',
  402: 'store-payer',
  403: 'store-payer',
  404: 'store-payer',
  405: 'store-payer',
  500: 'store-payer',
  600: 'store-payer',
  888: 'store-payer',
};
