import { compose, prop, __, equals } from 'ramda';
import { getState, getGetter } from '@/helpers/vuex';
import { postfixLut } from '@/components/desktop/step1/payer-info/constant/payer-info.constant';
import { isLoginUser } from '@/helpers/is-from-app';

/** 產生所需要的 postfix 訊息 */
export const makePostfix = compose(prop(__, postfixLut), getState('PaymentInfo', 'payment'));

/** 是否為 [門取或行動支付] */
export const isOtherPayer = equals('StoreInfo');

/** 選擇折扣後是否為 [0元訂單] */
export const isZeroOrder = compose(equals(0), getGetter('AmountDetail', 'realInstantAmount'));

/** [門取或行動支付] 或 [0元訂單] 或 [未登入] 不顯示「記住信用卡資訊下次使用」component */
export const isShowCardKeepMsg = function (module) {
  return !(isOtherPayer(module) || isZeroOrder()) && isLoginUser();
};
