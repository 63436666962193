import { openModal } from '@/helpers/modal';
import { commitMutation, renderState } from '@/helpers/vuex';
import {
  checkDelivery,
  checkPaymentType,
  onShippingComplete,
  createParameterArray,
  sendGA4sEvent,
  checkOutToCart,
} from '@/helpers/tracking/ga';
import { setCookie } from '@/helpers/cookie';
import { redirectHostname, isMobile, getOrderListPath, isNotFromMuchMuch } from '@/helpers/is-from-app';
import { invokePaymentTypeRedirect } from '@/helpers/order-process.js';
import { paymentEnum } from '@/constant/payment-info';

const cartType = renderState('CartDetail', 'cartType');
// res.data.code為其它或API問題皆導去 cartMain 頁
// online (PC || Mobile || APP) => cartMain || cartChoice || cartChoice
const redirectPath = isMobile() ? '/m/cart/choice' : `/cart/main?type=${cartType}`;

const methods = {
  /** 成立訂單 API 完成後的轉跳判斷 */
  orderProcessResponseToNext(res) {
    // 判斷 timeout
    if (res.response && typeof res.response.status === 'number' && res.response.status >= 400) {
      throw res;
    }
    const hasResponse = res && res.data;
    const message = hasResponse && res.data.description ? res.data.description : '訂單成立失敗！';
    // cvc clean 清除 Store 資料
    commitMutation('CardInfo', 'cvc', '');
    if (hasResponse) {
      // 刷卡失敗 (有 csrf 退回結帳頁) -> 改用其他付款方式
      const creditCardFailed = res.data.data.csrf;
      if (creditCardFailed) {
        alert(message);
        // 更新 checksum 值
        if (res.data.data.checksum) {
          commitMutation('AmountDetail', 'checksum', res.data.data.checksum);
        }
        // 更新 csrf 偷啃
        commitMutation('AmountDetail', 'csrf', res.data.data.csrf);
        // 返回結帳頁 for PC
        commitMutation('Step1Component', 'dynamicComponent', 'Checkout');
        if (isMobile()) {
          // 返回結帳畫面
          this.loadingGoBack();
        }
        return;
      }
      const blacklist = 3;
      if (res.data.code === blacklist) {
        alert(message);
        window.location.href = process.env.VUE_APP_ONLINE;
        return;
      }
      const hasResData = typeof res.data.data.finish === 'undefined' ? res.data.data : res.data.data.finish;
      const fetchSuccessCode = 1;
      if (hasResData && res.data.code === fetchSuccessCode) {
        const { execScript, paymentKind, orderId, paymentReviewDueDate, realAmount } = hasResData;
        const finishedPath = isMobile() ? '/m/cart/finished' : '/cart/finished';
        // set ga to finished
        const approvePaymentKindList = [
          'LINE_PAY',
          'APPLE_PAY',
          'JKO_PAY',
          'PI_PAY',
          'PLUS_PAY',
          'TW_PAY',
          'EASY_PAY',
          'ZERO',
          'SENAO_MART',
          'CREDIT_CARD_ONCE',
          'CREDIT_CARD_BONUS',
          'CREDIT_CARD_INSTALLMENT',
          'ATM',
          'ZINGALA',
        ];
        const cartDetail = renderState('CartDetail', 'cartDetail');
        const deliveryType = renderState('DeliveryInfo', 'delivery');
        const isApprovePaymentType = approvePaymentKindList.indexOf(paymentKind) !== -1;
        /** GA4
         * add_shipping_info
         * add_payment_info
         */
        const itemParameters = createParameterArray(cartDetail);
        const defaultParameterForGA = {
          currency: 'TWD',
          value: realAmount,
          items: itemParameters,
        };
        const userSelectDeliveryType = checkDelivery(deliveryType);
        const parameterForShipping = Object.assign({ shipping_tier: userSelectDeliveryType }, defaultParameterForGA);
        sendGA4sEvent('add_shipping_info', parameterForShipping);
        const userPickPaymentType = renderState('PaymentInfo', 'payment');
        const isMobileAndIsMobilePayment = userPickPaymentType === paymentEnum.MobilePayment;
        const currentPaymentType = renderState('PaymentInfo', isMobileAndIsMobilePayment ? 'mobilePayment' : 'payment');
        const userSelectPaymentType = checkPaymentType(currentPaymentType);
        const parameterForPayment = Object.assign({ payment_type: userSelectPaymentType }, defaultParameterForGA);
        sendGA4sEvent('add_payment_info', parameterForPayment);
        // set GA from PC at online domain
        if (!isMobile() && isNotFromMuchMuch()) {
          // UA
          onShippingComplete(2, checkDelivery(deliveryType));
        }
        if (isApprovePaymentType) {
          checkOutToCart(cartDetail, 3, checkPaymentType(currentPaymentType));
        }
        // Insider
        setCookie(`finish_${orderId}`, `${redirectHostname()}${finishedPath}`, 60);
        this.lock = false;
        if (execScript) {
          commitMutation('OrderFinishInfo', 'execScript', execScript);
          if (paymentReviewDueDate?.length) {
            openModal('ZingalaModal');
            commitMutation('OrderFinishInfo', 'paymentReviewDate', paymentReviewDueDate);
            this.$refs.zingalaModal.countDownTimer();
            return;
          }
        }
        invokePaymentTypeRedirect(hasResData);
        return;
      }
    }
    alert(message);
    window.location.href = `${redirectHostname()}${redirectPath}`;
  },
  catchResponse(error) {
    console.log(error);
    /** timeout */
    if (`${error}`.replace('Error: ', '').indexOf('timeout') > -1) {
      alert('連線逾時！');
      window.location.href = getOrderListPath();
      return error;
    }
    /** response status 400 以上 */
    if (
      (error.response && typeof error.response.status === 'number' && error.response.status >= 400) ||
      error.response === undefined
    ) {
      alert('API 錯誤！');
      window.location.href = getOrderListPath();
      return error;
    }
    this.lock = false;
    return error;
  },
};

export default {
  methods,
};
