const regexes = [
  /^[0-9]{3,4}$/, // 3-4字的數字 (美國運通)
];

const getMessage = '必填，必須是數字';
const validate = (value) => regexes.every((x) => x.test(value));

/** 自訂信用卡 CVC 格式 */
export default {
  getMessage,
  validate,
};
