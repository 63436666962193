const getMessage = () => `請輸入有效的統一編號`;

const validate = (value) => {
  const invalidList = '00000000,11111111';
  if (/^\d{8}$/.test(value) === false || invalidList.indexOf(value) !== -1) {
    return false;
  }

  const validateOperator = [1, 2, 1, 2, 1, 2, 4, 1];
  let sum = 0;
  // 個位數 + 十位數
  const calculate = function (product) {
    const ones = product % 10;
    const tens = (product - ones) / 10;
    return ones + tens;
  };
  for (let i = 0; i < validateOperator.length; i++) {
    sum += calculate(value[i] * validateOperator[i]);
  }

  return sum % 10 === 0 || (value[6] === '7' && (sum + 1) % 10 === 0);
};

/** 自訂驗證 公司統一編號 格式 */
export default {
  getMessage,
  validate,
};
