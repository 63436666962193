import { paymentEnum } from '@/constant/payment-info';

/** 付款方式與 store module 對照表 */
export const paymentModuleLut = {
  [paymentEnum.creditCardBonus]: 'CardInfo',
  [paymentEnum.creditCardOnetime]: 'CardInfo',
  [paymentEnum.creditCardInstallment]: 'CardInfo',
  [paymentEnum.atmTransfer]: 'ATMInfo',
  [paymentEnum.storePayment]: 'ATMInfo',
  [paymentEnum.linePayment]: 'ATMInfo',
};

export const postfixLut = {
  [paymentEnum.creditCardBonus]: '(請填信用卡持卡人姓名)',
  [paymentEnum.creditCardOnetime]: '(請填信用卡持卡人姓名)',
  [paymentEnum.creditCardInstallment]: '(請填信用卡持卡人姓名)',
  [paymentEnum.atmTransfer]: '',
  [paymentEnum.storePayment]: '',
  [paymentEnum.linePayment]: '',
};
