import { getMarqueeList } from '@/api/get-marquee-list';

const methods = {
  getMarqueeListResponse(res) {
    if (res.data.code !== 1) {
      return false;
    }

    return this.$store.dispatch('Marquee/updateMarquee', res.data.data.marquees);
  },
};

const computed = {
  checkCallMarqueeApi() {
    return this.$store.getters['Marquee/checkCall'];
  },
  getMarquee() {
    return this.$store.getters['Marquee/getList'] || [];
  },
};

const mounted = function () {
  // store 沒有才呼叫跑馬燈 API 取資料
  if (this.checkCallMarqueeApi === false) {
    getMarqueeList({ position: 3 }).then(this.getMarqueeListResponse);
  }
};

export default {
  // data,
  methods,
  computed,
  mounted,
};
