<template>
  <modal
    componentName="VtpModal"
    :isModalStatic="true"
    :modalSizeCssClass="isMobile ? '' : 'sm'"
    bodyCssClasses="plump"
    firstButtonText="我知道了">
    <template v-slot:body>
      <h3 class="blue">電子票券貼心提醒</h3>
      <ul class="has-type-decimal text-muted">
        <li>
          訂單經確認付款完成後，預計30分鐘內將發送電子票券開立通知至
          <b class="notice">付款人</b>
          Email及手機簡訊，請
          <b class="notice">付款人</b>
          留意通知訊息，謝謝。
        </li>
        <li>查看電子票券訊息，請登入會員</li>
        至
        <b class="notice">訂單紀錄>電子票券</b>
        查詢。
        <li>消費時請向店家出示票券序號即可折抵使用。</li>
      </ul>
    </template>
  </modal>
</template>
<script>
import { isMobile } from '@/helpers/is-from-app';
import Modal from '@/components/common/common-modal.vue';
import { openModal } from '@/helpers/modal';

export default {
  name: 'vtp-modal',
  components: {
    Modal,
  },
  computed: {
    isMobile,
  },
  mounted() {
    openModal('VtpModal');
  },
};
</script>
