<template>
  <div :class="$attrs.isMobile ? 'select-group third' : 'css-td'">
    <select
      :name="birthErrMsg"
      v-model="year"
      :style="$attrs.isMobile ? '' : 'margin-right: 10px'"
      @change="checkLeapYearSetDate(year, month)"
      v-validate="'required'">
      <option value="">西元年</option>
      <option v-for="(year, index) in years" :value="year" :key="`birth-${year}-${index}`">
        {{ year }}
      </option>
    </select>
    <select
      :name="birthErrMsg"
      v-model="month"
      :style="$attrs.isMobile ? '' : 'margin-right: 10px'"
      @change="checkLeapYearSetDate(year, month)"
      v-validate="'required'">
      <option value="">月份</option>
      <option v-for="(month, index) in months" :value="month" :key="`birth-${month}-${index}`">
        {{ month }}
      </option>
    </select>
    <select :name="birthErrMsg" v-model="date" v-validate="'required'">
      <option value="">日期</option>
      <option v-for="(date, index) in dates" :value="date" :key="`birth-${date}-${index}`">
        {{ date }}
      </option>
    </select>
    <p class="error" v-if="errors.has(birthErrMsg)">
      {{ errors.first(birthErrMsg) }}
    </p>
  </div>
</template>
<script>
import { genBirthYears, genDates, genMonths } from '@/helpers/date';
import { createComputed } from '@/helpers/vuex';

export default {
  name: 'select-birthday',
  inject: ['$validator'],
  props: ['birthErrMsg'],
  data: () => ({
    dates: genDates,
    months: genMonths,
    years: genBirthYears,
  }),
  computed: {
    year: createComputed('OrderInfo', 'userPickBirthYear'),
    month: createComputed('OrderInfo', 'userPickBirthMonth'),
    date: createComputed('OrderInfo', 'userPickBirthDate'),
  },
  methods: {
    checkLeapYearSetDate(year, month) {
      // for Radio button validate before next step
      this.$validator.validate('*');
      const commonMonth = ['04', '06', '09', '11'];
      const isCommonMonth = commonMonth.some((x) => x === month);
      let dateLength = isCommonMonth ? 30 : 31;
      if (month === '02') {
        const isLeapYear = new Date(year, 1, 29).getDate() === 29;
        dateLength = isLeapYear ? 29 : 28;
      }
      while (this.dates.length) {
        this.dates.pop();
      }
      Array.from({ length: dateLength }, (x, i) => this.dates.push(i + 1));
      return this.dates;
    },
  },
};
</script>
