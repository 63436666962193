/** 取得全部表單錯誤訊息 */
export const getMessage = (item) => {
  switch (item.field) {
    case 'agreeCheck':
      return '請勾選同意若後續辦理退貨作業，同意由神腦國際代為處理發票及銷貨退回證明單，以加速退貨退款作業';
    case 'agreeZingalaCheck':
      return '請勾選 我已閱讀及明白付款說明 並同意個資使用宣告';
    case 'installment':
      return '請選擇分期付款期數';
    case 'agreeCardKeep':
      return '請勾選同意記住信用卡，以供週期購續訂、下次訂購時使用。';
    case 'mobilePayment':
      return '請選擇行動支付方式';
    default:
      return item.msg;
  }
};

/** 僅取得付款人表單錯誤訊息 */
export const getPayerInfoMessage = (item) => {
  switch (item.field) {
    case 'payerName':
      return '請填付款人姓名';
    case 'payerPhone':
      return '請填付款人手機號碼';
    case 'payerEmail':
      return '請填付款人信箱';
    case 'city':
      return '請填付款人地址';
    case 'payerCityName':
      return '請填付款人地址';
    case 'payerTownName':
      return '請填付款人地址';
    case 'payerAddressName':
      return '請填付款人地址';
    default:
      return item.msg;
  }
};
