import { renderState, renderGetter } from '@/helpers/vuex';
import { getCityNameById, getTownNameById, getCityAndTownIdByZip, regexpAddress } from '@/helpers/city-town';
import { isMobile } from '@/helpers/is-from-app';
import { paymentEnum } from '@/constant/payment-info';

/**
 * 建立訂單參數
 * @param {Object} params 訂單 params
 * @return {Object} params
 */
export function setOrder(params = {}) {
  const csrf = renderState('AmountDetail', 'csrf');
  const instantAmount = renderGetter('AmountDetail', 'realInstantAmount') || 0;
  if (csrf.token !== '') {
    params[csrf.token] = csrf.hash;
    params.checksum = renderState('AmountDetail', 'checksum');
  }
  params.cartAmount = parseInt(renderGetter('AmountDetail', 'realTotalAmount'), 10) || 0;
  params.useVCash = parseInt(renderState('AmountDetail', 'deductedVCashState'), 10) || 0;
  params.useHamiPoint = parseInt(renderState('AmountDetail', 'deductedHamiPointState'), 10) || 0;
  params.useEdenred = parseInt(renderState('AmountDetail', 'deductedEdenredState'), 10) || 0;
  params.deliveryType = parseInt(renderState('DeliveryInfo', 'delivery'), 10) || 0;
  params.invoiceType = renderState('InvoiceInfo', 'invoiceDefault');
  params.paymentToken = renderState('PaymentInfo', 'applePayPaymentToken');
  const userPickPaymentType = renderState('PaymentInfo', 'payment');
  const isMobileAndIsMobilePayment = userPickPaymentType === paymentEnum.MobilePayment;
  const currentPaymentType = renderState('PaymentInfo', isMobileAndIsMobilePayment ? 'mobilePayment' : 'payment');
  /** 0元訂單paymentType固定傳100 */
  params.paymentType = instantAmount === 0 ? paymentEnum.creditCardOnetime : currentPaymentType;

  /** 信用卡 */
  const isCreditCard =
    [paymentEnum.creditCardOnetime, paymentEnum.creditCardBonus, paymentEnum.creditCardInstallment].indexOf(
      params.paymentType
    ) > -1;
  const isZeroOrder = instantAmount === 0;

  if (isZeroOrder) {
    params.invoiceType = '01';
  }
  if (isCreditCard && instantAmount > 0) {
    /** cvc */
    params.cardCvc = renderState('CardInfo', 'cvc');
    const cardNo = renderState('CardInfo', 'cardNo');
    // 是否使用快速授權的卡片？
    const isRtype = renderState('PaymentInfo', 'isPayerReadOnly');
    const isShowRecord = renderState('PaymentInfo', 'isShowRecord') || isRtype;
    /** 信用卡有效期限 */
    params.cardMonth = isShowRecord
      ? `${renderState('CardInfo', 'quickExpiredMonth')}`
      : `${renderState('CardInfo', 'cardExpiredMonth')}`;
    params.cardYear = isShowRecord
      ? `${renderState('CardInfo', 'quickExpiredYear')}`
      : `${renderState('CardInfo', 'cardExpiredYear')}`;
    /** 不沿用卡號 */
    if (cardNo.length >= 15) {
      /** 信用卡卡號 */
      params.cardNo = cardNo;
    }
  }

  /** 信用卡分期 */
  if (params.paymentType === paymentEnum.creditCardInstallment) {
    const installments = renderState('InstallmentInfo', 'installments');
    const installmentIndex = renderState('InstallmentInfo', 'installmentIndex');
    /** 分期期數 (會複寫 paymentType) */
    params.paymentType = 100 + installments[installmentIndex].period;
    /** 分期利率 */
    params.cardRate = installments[installmentIndex].rate;
  }

  /** 中租零角無卡分期 */
  if (params.paymentType === paymentEnum.ZingalaPayment) {
    params.payInstallments = renderGetter('ZingalaInfo', 'zingalaUserSelectPeriod');
  }

  /** 訂購人資訊寫入 */
  params.memberName = renderState('OrderInfo', 'name');
  params.memberMobile = renderState('OrderInfo', 'mobilePhone');
  const memberAddress = renderState('OrderInfo', 'address');
  params.memberAddress = regexpAddress(memberAddress);
  params.memberCity = renderState('OrderInfo', 'addressCity');
  params.memberDistrict = renderState('OrderInfo', 'addressTown');
  params.memberZipCode = renderState('OrderInfo', 'addressZip');

  /** 收件人資訊與配送資訊 */
  // 如果是電子票券車則不需送收件人資訊 ; 配送方式代碼 0: 免配送 / 1: 宅配 / 2: 門市取貨
  if (params.deliveryType !== 0) {
    params.receiptEmployee = renderState('RecipientInfo', 'employeeId');
    params.receiptName = renderState('RecipientInfo', 'name');
    params.receiptEmail = renderState('RecipientInfo', 'email');
    if (renderState('RecipientInfo', 'localPhone') !== '') {
      params.receiptPhoneCode = renderState('RecipientInfo', 'localPhoneArea');
      params.receiptPhoneNo = renderState('RecipientInfo', 'localPhone');
      params.receiptPhoneExt = renderState('RecipientInfo', 'localPhoneExt');
    }
    params.receiptMobile = renderState('RecipientInfo', 'mobilePhone');
    // 是否為宅配
    const isDeliveryIsHome = params.deliveryType === 1;
    const cityName = isDeliveryIsHome
      ? renderState('RecipientInfo', 'addressCity')
      : renderState('RecipientInfo', 'senaoCity');
    const townName = isDeliveryIsHome
      ? renderState('RecipientInfo', 'addressTown')
      : renderState('RecipientInfo', 'senaoTown');
    // 以裝置區分門市地址來源
    const getAddress = isMobile()
      ? renderState('RecipientInfo', 'senaoStoreAddress').replace(`${cityName}${townName}`, '')
      : renderGetter('CityTownStores', 'address');
    params.receiptCity = cityName;
    params.receiptDistrict = townName;
    const deliveryIsStoreByDevice = isMobile()
      ? renderState('RecipientInfo', 'senaoStoreZipcode')
      : renderGetter('CityTownStores', 'zipcode');
    const deliveryZipCode = isDeliveryIsHome ? renderState('RecipientInfo', 'addressZip') : deliveryIsStoreByDevice;
    params.receiptZipCode = deliveryZipCode;
    const receiptCityAndTownObj = getCityAndTownIdByZip(deliveryZipCode);
    params.receiptCityId = receiptCityAndTownObj.cityId;
    params.receiptTownId = receiptCityAndTownObj.townId;
    const receiptAddress = renderState('RecipientInfo', 'address');
    params.receiptAddress = regexpAddress(isDeliveryIsHome ? receiptAddress : getAddress);
    params.receiptStoreId = isMobile()
      ? renderState('RecipientInfo', 'senaoStoreId')
      : renderGetter('CityTownStores', 'storeId');
    params.receiptStoreName = isMobile()
      ? renderState('RecipientInfo', 'senaoStore')
      : renderGetter('CityTownStores', 'store');
  }

  /** 是否儲存收件人 (step1 recipient.isSave) */
  const isSave = renderState('RecipientInfo', 'isSave');
  if (isSave !== null) {
    /** isSave 為 true 且不為行動裝置時才能為 1 (mobile 版在 step1 進入 step2 時已經存入不需重複再存) */
    params.isAddReceipt = isSave === true && isMobile() === false ? 1 : 0;
  }

  /** 付款人表單來源判斷 */
  const payerType = isCreditCard || isZeroOrder ? 'CardInfo' : 'StoreInfo';
  params.payerEmail = document.querySelector('input[name=payerEmail]')
    ? document.querySelector('input[name=payerEmail]').value
    : renderState(payerType, 'email');
  const payerAddressDOM = document.querySelector('#payer-address');
  const payerAddressVuex = renderState(payerType, 'address');
  params.payerAddress = regexpAddress(payerAddressDOM ? payerAddressDOM.value : payerAddressVuex);

  // 0 元訂單或進入 SSL loading 畫面無法 dom querySelect 時再去取 store 內的值
  params.payerCity = document.querySelector('#payer-city')
    ? document.querySelector('#payer-city').options[document.querySelector('#payer-city').selectedIndex].text
    : renderState(payerType, 'addressCity');
  params.payerDistrict = document.querySelector('#payer-town')
    ? document.querySelector('#payer-town').options[document.querySelector('#payer-town').selectedIndex].text
    : renderState(payerType, 'addressTown');
  const payerZipCode = document.querySelector('#payer-zip')
    ? document.querySelector('#payer-zip').value
    : renderState(payerType, 'addressZip');
  params.payerZipCode = payerZipCode;

  params.payerMobile = renderState(payerType, 'mobilePhone');
  params.payerName = renderState(payerType, 'name');

  const payerCityAndTownObj = getCityAndTownIdByZip(payerZipCode);
  params.payerCityId = payerCityAndTownObj.cityId;
  params.payerTownId = payerCityAndTownObj.townId;

  const phoneCode = renderState(payerType, 'localPhone');
  if (phoneCode !== '') {
    params.payerPhoneCode = renderState(payerType, 'localPhoneArea');
    params.payerPhoneNo = renderState(payerType, 'localPhone');
    params.payerPhoneExt = renderState(payerType, 'localPhoneExt');
  }

  /** 是否儲存付款人資訊 */
  if (payerType === 'CardInfo') {
    params.isAddPayer = renderState('CardInfo', 'cardKeep') === true ? 1 : 0;
  }

  let invoiceCity = document.querySelector('#invoice-city')
    ? document.querySelector('#invoice-city').options[document.querySelector('#invoice-city').selectedIndex].text
    : renderState('InvoiceInfo', 'addressCity');
  let invoiceDistrict = document.querySelector('#invoice-town')
    ? document.querySelector('#invoice-town').options[document.querySelector('#invoice-town').selectedIndex].text
    : renderState('InvoiceInfo', 'addressTown');

  const invoiceZip = document.querySelector('#invoice-zip')
    ? document.querySelector('#invoice-zip').value
    : renderState('InvoiceInfo', 'addressZip');

  const invoiceCityAndTownObj = getCityAndTownIdByZip(invoiceZip);
  const invoiceCityId = invoiceCityAndTownObj.cityId || renderState('InvoiceInfo', 'addressCityId');
  const invoiceTownId = invoiceCityAndTownObj.townId || renderState('InvoiceInfo', 'addressTownId');

  if (invoiceCity === '' || invoiceCity === '請選擇') {
    invoiceCity = getCityNameById({ cityId: invoiceCityId });
    invoiceDistrict = getTownNameById({ cityId: invoiceCityId, townId: invoiceTownId });
  }

  /** 發票載具 */
  switch (params.invoiceType) {
    /** 手機載具 (/) */
    case '02':
      params.invoiceDeviceId = renderState('InvoiceInfo', 'phoneDeviceCode');
      params.isSaveMobileBarcode = renderState('InvoiceInfo', 'isSaveDeviceCode') === true ? 1 : 0;
      break;
    /** 自然人憑證條碼載具 */
    case '03':
      params.invoiceDeviceId = renderState('InvoiceInfo', 'idDeviceCode');
      break;
    /** 發票捐贈代碼 */
    case '04':
      params.invoiceDonateId = renderState('InvoiceInfo', 'donateInvoice');
      break;
    /** 電子發票-公司(紙本) */
    case '05': {
      params.invoiceZipCode = invoiceZip;
      const invoiceAddressVuex = renderState('InvoiceInfo', 'address');
      params.invoiceAddress = regexpAddress(invoiceAddressVuex);

      params.invoiceTaxId = renderState('InvoiceInfo', 'companyNo');
      params.invoiceTitle = renderState('InvoiceInfo', 'companyTitle');
      params.invoiceName = renderState('InvoiceInfo', 'recipient');
      params.invoiceCityId = invoiceCityId;
      params.invoiceTownId = invoiceTownId;
      params.invoiceCity = invoiceCity;
      params.invoiceDistrict = invoiceDistrict;
      /** 是否儲存統編:  0 or 1 */
      params.isAddUniform = renderState('InvoiceInfo', 'alwaysUniform') === true ? 1 : 0;
      break;
    }
    default:
      break;
  }

  return params;
}
