<template>
  <div :class="{ content: isMobile }">
    <div v-if="isMobile">
      <h5>神腦生活會員服務條款</h5>
      歡迎您加入神腦會員，為了保障您的權益，請務必詳讀本條款：
    </div>
    <div v-else>
      <p class="spacing bottom">歡迎您加入神腦會員，為了保障您的權益，請務必詳讀本條款：</p>
    </div>
    <dl :class="{ manual: !isMobile }">
      <dt :class="{ orange: isMobile }">一、 會員同意及注意事項</dt>
      <dd>
        <ul class="has-type-decimal">
          <li>當您於神腦生活完成註冊手續成為神腦會員時，即視為已閱讀、瞭解並同意接受本服務條款之所有內容。</li>
          <li>
            神腦生活有權於任何時間基於需要而修改或變更本服務條款之內容，並公佈於網站內，建議您隨時注意相關修改或變更。
          </li>
          <li>
            您於本服務條款修改或變更後繼續使用神腦生活所提供之任一服務時，即視為您已閱讀、瞭解並同意接受該等修改或變更。如果您不同意本服務條款內容之全部或ㄧ部份時，或者您所屬的國家或地域之法律排除本服務條款內容之全部或ㄧ部份之適用時，您應立即停止使用神腦生活之服務。
          </li>
          <li>
            會員註冊成功後，神腦生活將不定期主動發送神腦(線上)、神腦關係企業及合作廠商之相關活動資訊。若會員收到資訊後拒絕接受行銷時，可自行登入會員功能取消，神腦生活將協助您，儘速取消該行銷訊息提供服務。
          </li>
          <li>
            若您未滿20歲，除應符合上述規定外，並應於您的法定代理人閱讀、瞭解並同意本服務條款之所有內容及其後之修改或變更後，方得註冊或使用神腦生活。當您使用或繼續使用神腦生活所提供之任一服務時，即推定您的法定代理人已閱讀、瞭解並同意接受本服務條款之所有內容及其後之修改或變更。
          </li>
        </ul>
      </dd>
      <dt :class="{ orange: isMobile }">二、 會員資料之正確及更新</dt>
      <dd>
        <ul class="has-type-decimal">
          <li>註冊會員時，應填寫正確、完整之個人資料。</li>
          <li>個人資料有所變更異動時，應即時更新資料，確保其正確性。</li>
          <li>
            如您提供任何錯誤或不實的資料、或欠缺必要之資料、或原提供之資料已不符合真實，神腦生活有權暫停或終止您的帳號，並拒絕您使用本服務之全部或一部份。若您所提供資料有錯誤、不實或其他類似情事之一，致您、第三人或神腦權益受損，需請您自行處理及負擔相關法律及賠償責任，概與神腦無涉。
          </li>
        </ul>
      </dd>
      <dt :class="{ orange: isMobile }">三、 會員帳號之保管及安全</dt>
      <dd>
        <ul class="has-type-decimal">
          <li>您的會員帳號僅供您個人使用，不得轉借、轉讓他人或與他人合用。</li>
          <li>您有義務妥善保管會員帳號、密碼及其他相關資訊，每次登入連線完後，務必登出系統結束帳號使用。</li>
          <li>
            若您發現或疑似會員帳號內個人資料，遭他人非法使用或有任何安全性問題發生時，請您立即以電話、電子郵件或線上客服等方式，通知神腦生活；若您的帳號密碼確係遭他人冒用時，神腦生活將請您提供相關個人資料供驗證，並待驗證完成後，限制或暫停該爭議帳戶之使用權限。
          </li>
          <li>
            如因您的保管疏忽或同意由第三人使用其帳號，導致會員帳號、密碼遭他人非法使用，致您、第三人或神腦權益受損，需請您自行處理及負擔相關法律及賠償責任，概與神腦無涉。
          </li>
        </ul>
      </dd>
      <dt :class="{ orange: isMobile }">四、 客戶資料揭露對象及情形</dt>
      <dd>
        <ul class="has-type-decimal">
          <li>
            神腦生活為提供給您完整服務，在服務目的範圍之必要運用作業下，將會揭露您的個人資料與神腦關係企業(包括但不限於神揚保險代理人股份有限公司)、及因營業而委任處理相關事務之第三人(包括但不限於金融機構、合作商、物流商等服務提供者)。
          </li>
          <li>
            除經過您的同意外，神腦生活提供您的個人資料與第三人尚可能包含以下情形：
            <br />
            a.於上開特定目的範圍作業利用之必要。
            <br />
            b.合於個人資料保護法第20條但書规定為特定目的外之利用。
            <br />
            c.法律規定或受司法機關與其他有權機關基於法定程序之要求。
            <br />
            d.在緊急情況下為维護其他會員或第三人之合法權益。
            <br />
            e.為维護會員服務系统的正常運作。
            <br />
            f.會員透過本服務購物、兌换贈品、參加抽獎活動等因素而產生的金流、物流等之必要資訊。
            <br />
          </li>
          <li>
            依法令規範、或取得您的同意外，神腦生活不會向上述以外之第三人揭露您的個人資料。若拒絕神腦生活揭露您的個人資料，請您儘速通知神腦生活，但您清楚知悉且同意，拒絕揭露您的個人資料恐致神腦生活將無法繼續為您提供服務。
          </li>
        </ul>
      </dd>
      <dt :class="{ orange: isMobile }">五、 合法及合理使用義務</dt>
      <dd>
        會員承諾不以任何非法目的或方式使用本服務，並承諾遵守中華民國相關法令、主管機關規定，及所有使用網際網路之國際慣例。如會員係中華民國以外之使用者，並同意遵守所屬國家或地域之法令。
        <br />
        <h5>您不得利用本服務從事侵害他人權益或違法行為（包括但不限於下列行為）：</h5>
        <ul class="has-type-decimal">
          <li>
            公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案。
          </li>
          <li>侵害或毀損他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。</li>
          <li>違反依法律或契約所應負之保密義務。</li>
          <li>冒用他人名義使用本服務。</li>
          <li>傳輸或散佈電腦病毒或任對電腦軟、硬體產生破壞之程式碼資料。</li>
          <li>濫發垃圾郵件、廣告郵件、連銷信、違法之多層次傳銷訊息等。</li>
          <li>從事未經神腦生活事前授權的商業行為。</li>
          <li>其他神腦生活合理認為不適當之行為。</li>
        </ul>
      </dd>
      <dt :class="{ orange: isMobile }">六、 隱私權保護政策</dt>
      <dd>
        會員所註冊或使用本服務時所提供之個人資料，神腦生活將依「隱私權保護政策」為利用與保護。若您因使用本服務，致生糾紛，以本服務原資料庫所記錄之電子資料為認定標準，但經您提出合理佐證資料，並由神腦生活核對無誤者，不在此限。
      </dd>
      <dt :class="{ orange: isMobile }">七、 商品交易事項</dt>
      <dd>
        <ul class="has-type-decimal">
          <li>商品交易頁面呈現之商品資訊為契約之一部份。</li>
          <li>會員若有逾越商品購買上限數之下單，神腦生活僅依該商品數量上限出貨。</li>
          <li>
            訂購產品後倘任意退換貨、取消訂單、轉賣銷售或有其他不適當，而造成神腦生活作業上困擾或損害之行為，神腦生活均可視情況拒絕交易或永久取消會員資格。
          </li>
          <li>
            如註冊之會員門號並非本人實際持有，且利用該門號進行會員註冊以獲取之所有會員神腦幣或從事其他相關會員福利（包括但不限於優惠劵），因涉及不法利用他人門號且有損害第三人及本公司權利之虞，經本公司調查屬實後，本公司得註銷該客戶之所有註冊帳號，以及收回所有本公司提供之會員神腦幣；如將前述不法取得之神腦幣，使用於神腦生活購物網站及神腦各實體門市購物，或有賺取價差等相關行為，本公司除得將該筆會員帳號予以進行永久停權，且依法訴追其法律責任外，本公司亦得依個案情形，予以取消該筆訂單、拒絕出貨、退回該筆交易款項，或追討會員神腦幣所換算之折價金額等相關處置。
          </li>
        </ul>
      </dd>
      <dt :class="{ orange: isMobile }">八、 本服務中斷或故障之處理</dt>
      <dd>
        神腦生活將依現時技術水準，確保本服務之穩定性，若本服務有中斷或故障等情事之一，神腦生活將盡最大可能儘速完成修正，且需請您配合採取適當之自我防護措施(例如：使用電腦防火牆、防毒軟體，及避免使用公共電腦登入本服務等)；就前開服務中斷或故障期間內，若致您有使用不便、資料喪失、錯誤、遭竄改，或其他直接/間接經濟損失，神腦就此不負擔相關法律及賠償責任。
      </dd>
      <dt :class="{ orange: isMobile }">九、 智慧財產權</dt>
      <dd>
        <ul class="has-type-decimal">
          <li>
            神腦生活使用之軟體或程式、網站上所有內容，包括但不限於著作、圖片、檔案、資訊、資料、網站架構、網站畫面的安排、網頁設計，均由神腦生活或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。
          </li>
          <li>
            任何人不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若您欲引用或轉載前述軟體、程式或網站內容，必須依法取得神腦生活或其他權利人的事前書面同意。尊重智慧財產權是您應盡的義務，如有違反，致該權利人或神腦權益受損，需請您自行處理及負擔相關法律及賠償責任。
          </li>
        </ul>
      </dd>
      <dt :class="{ orange: isMobile }">十、 本服務之終止</dt>
      <dd>
        <ul class="has-type-decimal">
          <li>
            基於維護交易安全及業務需求之考量，本服務有可能停止或終止提供服務之全部或一部份，會員不可因此而要求神腦生活提供任何賠償或補償。
          </li>
          <li>
            如會員違反本服務條款，神腦生活保留暫時停止或終止提供本服務之權利，會員不可以因此而要求任何賠償或補償。
          </li>
        </ul>
      </dd>
      <dt :class="{ orange: isMobile }">十一、 準據法與管轄法院</dt>
      <dd>
        <ul class="has-type-decimal">
          <li>本服務條款中，任何條款之全部或一部份無效時，其他條款仍應完全有效。</li>
          <li>本服務條款之解釋與適用，以及與本服務條款有關之爭議，均應依中華民國法令解釋及規章、慣例為依據處理。</li>
        </ul>
      </dd>
    </dl>
    <h5>隱私權政策</h5>
    維護個人隱私權且支持【個人資料保護法】之規定，請您詳閱以下有關隱私權保護相關內容。
    <dl>
      <dt :class="{ orange: isMobile }">一、 本政策之適用</dt>
      <dd>
        隱私權政策聲明適用於神腦生活及神腦門市，及其延伸服務網站活動時，所涉及的個人資料之收集、運用與保護。但不適用於您經由本網站搜尋連結至其他網站後，所進行之活動，關於您在其他網站的個人資料之保護，適用各該網站的隱私權保護政策。對於不屬於神腦生活之網站或網頁，均與神腦生活無關。
      </dd>
      <dt :class="{ orange: isMobile }">二、 個資使用範圍</dt>
      <dd>
        神腦生活為了提供會員完整良好的購物過程，將請會員於訂購時，填寫本服務所需之相關資料；除另有規定外，因此所取得會員個人資料，神腦生活僅作為會員所需的個別服務為主。
      </dd>
      <dt :class="{ orange: isMobile }">三、 個資使用期間</dt>
      <dd>
        神腦生活為提供會員更好之服務品質，將會在法令使用或會員同意下，將會員資料提供給合作之商品供應商、商品配送物流商或其關係企業，以提供相關保固服務、行銷及優惠訊息。神腦生活將持續使用會員所提供個人資料，直至會員提出停止使用。
      </dd>
      <dt :class="{ orange: isMobile }">四、 cookie技術之使用</dt>
      <dd>
        神腦生活將盡力保障所有個人資料之安全，並在會員的電腦中使用cookie技術來記錄【購物車】內的物品及個人化服務等用途，並無侵犯個人隱私之意圖，使用者可以經由瀏覽器的設定、取消、或限制此項功能，但有可能會影響到無法使用部份網站功能。
      </dd>
      <dt :class="{ orange: isMobile }">五、 個資安全注意事項</dt>
      <dd>
        神腦生活將謹慎的保護會員個人資料，會員有責任保護帳號密碼的機密性，避免會員的帳號密碼遭有心人士盜用，除非經您的同意、依法律之規定，或本服務銷售(行銷)等目的用途外，絕不會將會員的個人資料透漏予任何與神腦無關之第三人。
        <br />
        若是與他人共享電腦或使用公共電腦，請務必記得使用完各服務後需登出，並關閉瀏覽器視窗，並建議您不定期更改密碼，若因帳號密碼外洩造成損害，神腦生活將不負任何賠償責任。
      </dd>
      <dt :class="{ orange: isMobile }">六、 個資之查詢、更正及調閱等事項</dt>
      <dd>
        會員有權閱覽、查詢、補充或修改個人會員帳戶資料，神腦生活會不定期發送行銷訊息，會員可選擇取消訂閱相關訊息。
        <br />
        神腦生活保有權利向會員傳送服務相關特定訊息(服務通知、訊息)，若會員不願接收前述訊息，請與客服中心聯絡申請，但該會員帳號可能將無法享受完整服務，或完全無法使用該項服務。
      </dd>
      <dt :class="{ orange: isMobile }">七、 本政策之修訂</dt>
      <dd>
        神腦生活隱私權保護政策將因應需求，隨時進行修正，以落實保障使用者隱私權之立意。修正後的條款將刊登於本網站上，不另行個別通知，您可於神腦生活網站上詳閱修訂隱私權聲明及相關告知事項。
      </dd>
    </dl>
  </div>
</template>
<script>
export default {
  name: 'privacy-terms-content',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
