import { compose, gt, __, both, either, prop } from 'ramda';
import { commitMutations, commitMutation, dispatchAction, getState, getGetter } from '@/helpers/vuex';
import { getCityIdByName, getTownIdByName, regexpAddress } from '@/helpers/city-town';

/**  /getInfo API code: 1=OK, 2=未登入, 3=查無持卡人, 0=失敗 */
export const getInfoResponse = (res) => {
  const { code } = res.data;
  const { creditCardInfo } = res.data.data;
  switch (code) {
    case 1:
      commitMutations('CardInfo', {
        name: creditCardInfo.username,
        mobilePhone: creditCardInfo.mobile,
        localPhoneArea: creditCardInfo.userPhoneCode,
        localPhone: creditCardInfo.userPhoneNo,
        localPhoneExt: creditCardInfo.userPhoneExt,
        email: creditCardInfo.email,
        addressCity: creditCardInfo.cityName,
        addressTown: creditCardInfo.townName,
        address: regexpAddress(creditCardInfo.address),
        addressCityId: getCityIdByName({ cityName: creditCardInfo.cityName }) || '',
        addressTownId:
          getTownIdByName({
            cityName: creditCardInfo.cityName,
            townName: creditCardInfo.townName,
          }) || '',
      });
      commitMutation('CardInfo', 'code', code);
      // 呼叫快速刷卡 API
      dispatchAction('CardInfo', 'getQuickSettle');
      break;
    case 2:
      alert('請先登入會員');
      window.location.href = `${process.env.VUE_APP_ONLINE}/login?redirect=${process.env.VUE_APP_CART}%2Fcart%2Fmain`;
      break;
    default:
      // 其他: 查無持卡人資訊處理
      commitMutation('PaymentInfo', 'isShowRecord', false);
      commitMutation('CardInfo', 'code', code);
  }
};

/** 有折扣選項才會被寫到陣列裡 */
export const isShowDiscountItem = compose(gt(__, 0), prop('length'), getState('AmountDetail', 'getDiscountList'));

/** 福利金> 0？ */
const isVCash = compose(gt(__, 0), getState('AmountDetail', 'vCash'));

/** hamipoint > 0 ? */
const isHamiPoint = compose(gt(__, 0), getState('CartDetail', 'hamiPoint'));

/** 應付金額大於0元？ */
const isZeroOrder = compose(gt(__, 0), getGetter('AmountDetail', 'realTotalAmount'));

/** 若福利金 > 0 且應付總金額 > 0，才顯示福利金組件 */
export const isShowVCashBlock = both(isVCash, isZeroOrder);

/** 若HamiPoint > 0 且應付總金額 > 0，才顯示 HamiPoint 組件 for desktop 所以不用判斷來源是否為神腦購 */
export const isShowHamiPointBlock = both(isHamiPoint, isZeroOrder);

/** 若福利金或HamiPoint > 0 且應付總金額 > 0 才顯示實際金額 */
export const isShowRealAmount = either(isShowVCashBlock, isShowHamiPointBlock);
