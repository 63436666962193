import { getApplePayTransaction } from '@/api/get-applepay-transaction';
import { renderGetter } from '@/helpers/vuex';
import { numPadZeroes } from '@/helpers/format/dollar';

export const appleMerchantID = process.env.VUE_APP_APPLE_MERCHANT_ID;

export const checkShowApplePayOption = () => {
  const formatPrice = numPadZeroes(renderGetter('AmountDetail', 'realInstantAmount'));
  const paymentRequest = {
    supportedNetworks: ['AMEX', 'MASTERCARD', 'VISA', 'JCB'],
    total: {
      label: '神腦生活',
      amount: {
        currency: 'TWD',
        value: formatPrice,
      },
    },
  };
  NCPayment.applePay.setupPaymentRequest(paymentRequest, (result) => {
    // 代表瀏覽器支援 payment request api (或 apple pay)
    if (result.browserSupportPaymentRequest === false) {
      console.log('瀏覽器不支援 PaymentRequest');
      return;
    }
    if (result.canMakePaymentWithActiveCard === true) {
      console.log('該裝置有支援的卡片可以付款');
    }
  });
};

export const completeMerchantValidation = () => {
  NCPayment.applePay.setupApplePay({ appleMerchantID, countryCode: 'TW' });
  checkShowApplePayOption();
  NCPayment.applePay.onvalidatemerchant(async (event) => {
    const { validationURL } = event;
    const formData = new FormData();
    formData.append('validationURL', validationURL);
    await getApplePayTransaction(formData).then((res) => {
      const fetchSuccess = 1;
      if (res.data.code === fetchSuccess) {
        const { transactionSession } = res.data.data;
        NCPayment.applePay.completeMerchantValidation(transactionSession);
      }
      // if fail turn on btn
    });
  });
};
