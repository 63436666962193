import { fetchRegisterSenaoMembership, smsSendSenaoMembership } from '@/api/member-registration';
import { commitMutation, renderState } from '@/helpers/vuex';
import store from '@/store/index';
import { closeModal } from '@/helpers/modal';
import { createRegistrationItem, sendGA4sEvent } from '@/helpers/tracking/ga';

const checkCheckboxStatus = () => {
  const isCheckedAgreement = renderState('RegisterSenaoMembership', 'isDisabledCheckedAgreement');
  commitMutation('RegisterSenaoMembership', 'validateErrorMsg', isCheckedAgreement ? '' : '請勾選同意');
};

export const setFormatBirthdayForPOST = () => {
  const birthYear = renderState('OrderInfo', 'userPickBirthYear');
  const birthMonth = renderState('OrderInfo', 'userPickBirthMonth');
  const birthDate = renderState('OrderInfo', 'userPickBirthDate');
  return Number(birthYear) * 10000 + Number(birthMonth) * 100 + Number(birthDate);
};

/** 神腦會員註冊 */
const getMemberRegistration = function () {
  const formData = new FormData();
  formData.append('mobile', renderState('OrderInfo', 'mobilePhone'));
  formData.append('email', renderState('OrderInfo', 'email'));
  formData.append('name', renderState('OrderInfo', 'name'));
  formData.append('gender', renderState('OrderInfo', 'userPickGender'));
  formData.append('birthday', `${setFormatBirthdayForPOST()}`);
  const isAtStep1Modal = renderState('RegisterSenaoMembership', 'step') === 'Step1';
  !isAtStep1Modal ? formData.append('authCode', renderState('RegisterSenaoMembership', 'authCode')) : '';
  commitMutation('RegisterSenaoMembership', 'isSubmitBtnDisabled', true);
  fetchRegisterSenaoMembership(formData).then((res) => {
    const resCode = res.data.code;
    const successCode = 1;
    const goVerifySMSCode = 2;
    store.commit('RegisterSenaoMembership/setIsSubmitBtnDisabled', false);
    switch (resCode) {
      case successCode:
        closeModal('RegisterModal');
        sendGA4sEvent('sign_up', createRegistrationItem('註冊完成', 'cart'));
        return this.onConfirmPaymentAndOrderProcess();
      case goVerifySMSCode:
        store.commit('RegisterSenaoMembership/setStep', 'Step2');
        break;
      default:
        // 註冊失敗 -> alert -> off register modal
        // 驗證碼錯誤 -> alert -> return false
        alert(res.data.description);
        closeModal('RegisterModal');
    }
  });
};

const COUNT_DOWN_SECOND = 60;

/** step2: 倒數計時 **/
const countDownTimer = function () {
  if (this.countDown > 0) {
    this.isTimerBtn = true;
    this.isSendCodeBtn = false;
    setTimeout(() => {
      this.countDown -= 1;
      this.countDownTimer();
    }, 1000);
  }
  if (this.countDown === 0) {
    this.countDown = COUNT_DOWN_SECOND;
    this.isTimerBtn = false;
    this.isSendCodeBtn = true;
  }
};

const smsResponseHandle = function (res) {
  const resCode = res.data.code;
  const successCode = 1;
  if (resCode === successCode) {
    alert('發送成功');
    this.countDownTimer();
    return false;
  }
  // 0: alert ; 2: 超出發送次數 -> alert -> return false
  const resDescription = res.data.description;
  alert(resDescription);
  this.isTimerBtn = false;
  this.isSendCodeBtn = false;
  this.resMsg = resDescription;
  return false;
};

/** step2: 重新傳送簡訊驗證碼 */
const getSmsSend = function () {
  const orderInfoMobile = renderState('OrderInfo', 'mobilePhone');
  const formData = new FormData();
  formData.append('mobile', orderInfoMobile);
  smsSendSenaoMembership(formData).then(
    function (res) {
      smsResponseHandle.bind(this)(res);
    }.bind(this)
  );
};

export const memberRegistrationProcess = {
  data() {
    return {
      // 倒數計時
      countDown: COUNT_DOWN_SECOND,
      // 倒數狀態
      isTimerBtn: true,
      // 重新發送
      isSendCodeBtn: false,
      // sendSms API res description
      resMsg: '',
    };
  },
  methods: {
    getMemberRegistration,
    getSmsSend,
    countDownTimer,
    checkCheckboxStatus,
  },
};
