<template>
  <div v-if="$attrs.isMobile">
    <div class="fieldset">
      <label>* 姓名</label>
      <input
        type="text"
        placeholder="請輸入真實中文姓名"
        :name="nameErrMsg"
        v-model="orderInfoName"
        v-validate="'required|chinese-only'" />
      <p class="text-muted">此欄為查詢密碼、線上訂購等購物通知使用，請填寫真實姓名</p>
      <p class="error">{{ errors.first(nameErrMsg) }}</p>
    </div>
    <div class="fieldset">
      <label>* Email</label>
      <input
        type="text"
        placeholder="請輸入完整有效Email"
        :name="emailErrMsg"
        maxlength="40"
        autocomplete="off"
        v-model="orderInfoEmail"
        v-validate="'required|custom-email'" />
      <p class="text-muted">此欄為查詢密碼、線上訂購等購物通知使用，請填寫有效email</p>
      <p class="error">{{ errors.first(emailErrMsg) }}</p>
    </div>
    <div class="fieldset">
      <label>* 性別</label>
    </div>
    <div class="radio-group">
      <label class="radio-inline" v-for="(value, index) in gender" :key="index">
        <input
          type="radio"
          :name="sexErrMsg"
          :value="value.id"
          v-model="pickGender"
          v-validate="'required|included:1,0'" />
        {{ value.type }}
      </label>
      <p class="error">{{ errors.first(sexErrMsg) }}</p>
    </div>
    <div class="fieldset">
      <label>* 生日</label>
    </div>
    <select-birthday v-bind="$attrs" :birth-err-msg="'生日'" />
  </div>
  <div v-else>
    <form>
      <div class="css-table pure middle pull-center w-fix-2r">
        <div class="css-tr">
          <div class="css-th"></div>
          <div class="css-td">
            <p class="spacing top fn-sm"><b class="notice">* 為必填欄位，請確實填寫</b></p>
          </div>
        </div>
        <div class="css-tr">
          <div class="css-th align right">
            <label for="registerName">
              <b class="notice">*</b>
              姓名
              <br />
              <br />
              <br />
            </label>
          </div>
          <div class="css-td">
            <input
              id="registerName"
              :name="nameErrMsg"
              class="input-lg"
              placeholder="請輸入真實中文姓名"
              type="text"
              v-model="orderInfoName"
              v-validate="'required|chinese-only'" />
            <p class="sub">
              (此欄為門市紅利兌換時核對身份、後續訂單、退款等作業依據，
              <br />
              為維護您的權益，請務必填寫真實姓名)
            </p>
            <p class="error">{{ errors.first(nameErrMsg) }}</p>
          </div>
        </div>
        <div class="css-tr">
          <div class="css-th align right">
            <label for="registerEmail">
              <b class="notice">*</b>
              Email
              <br />
              <br />
              <br />
              <br />
            </label>
          </div>
          <div class="css-td">
            <input
              id="registerEmail"
              :name="emailErrMsg"
              class="input-lg"
              placeholder="請輸入Email"
              type="text"
              maxlength="40"
              autocomplete="off"
              v-model="orderInfoEmail"
              v-validate="'required|custom-email'" />
            <p class="sub">
              請輸入完整有效的Email，範例：abc123@domain.com.tw
              <br />
              (此欄為查詢密碼、線上訂購等購物通知使用，為維護您的權益，
              <br />
              請填寫有效email)
            </p>
            <p class="error">{{ errors.first(emailErrMsg) }}</p>
          </div>
        </div>
        <div class="css-tr">
          <div class="css-th align right">
            <b class="notice">*</b>
            性別
          </div>
          <div class="css-td">
            <label class="radio-inline" v-for="(value, index) in gender" :key="index">
              <input
                type="radio"
                :name="sexErrMsg"
                :value="value.id"
                v-model="pickGender"
                v-validate="'required|included:1,0'" />
              {{ value.type }}
            </label>
            <p class="error">{{ errors.first(sexErrMsg) }}</p>
          </div>
        </div>
        <div class="css-tr">
          <div class="css-th align right">
            <b class="notice">*</b>
            生日
          </div>
          <select-birthday :birth-err-msg="'生日'"></select-birthday>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { createComputed } from '@/helpers/vuex';
import store from '@/store/index';
import SelectBirthday from '@/components/common/modals/register-info-modal/select-birthday.vue';

const orderInfoName = {
  get() {
    return this.$store.state.OrderInfo.name;
  },
  set(value) {
    return store.commit('OrderInfo/name', value);
  },
};

const orderInfoEmail = {
  get() {
    return this.$store.state.OrderInfo.email;
  },
  set(value) {
    return store.commit('OrderInfo/email', value);
  },
};

export default {
  name: 'register-info',
  components: {
    SelectBirthday,
  },
  inject: ['$validator'],
  props: ['nameErrMsg', 'emailErrMsg', 'sexErrMsg'],
  data: () => ({
    gender: [
      { id: '1', type: '男' },
      { id: '0', type: '女' },
    ],
  }),
  computed: {
    pickGender: createComputed('OrderInfo', 'userPickGender'),
    orderInfoName,
    orderInfoEmail,
  },
};
</script>
