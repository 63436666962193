import axios from 'axios';
import qs from 'qs';

const PATH = `${process.env.VUE_APP_POST_API}${process.env.VUE_APP_API_ORDER_PROCESS}`;

/** 訂單成立 */
export default (args) => {
  // 是否顯示遮罩
  args.isShowLoading = false;

  // config
  const config = {
    method: 'POST',
    timeout: 5 * 60 * 1000, // (timeout 等待 5 分鐘)
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: qs.stringify(args),
    url: PATH,
  };

  /** Lab，目前暫時使用假 API，所以需要 false */
  return axios(config);
};
